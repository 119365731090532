<template>
  <v-container fill-height justify-content-start :class="{'pa-2': xsOnly, 'pa-4': smAndUp }">
    <v-layout column>
      <h2 class="display-2 raleway grey--text my-5">{{$t('sections.shifts')}}</h2>
      <v-layout wrap>
        <v-flex xs12 sm6 md3 class="mt-3" :class="{ 'pr-3': smAndUp }">
          <v-autocomplete
            color="secondary"
            :filter="autocompleteFilter"
            :label="$t('fields.status')"
            v-model="status"
            :items="shiftStatusItems"
            outlined
            clearable
            hide-details
          />
        </v-flex>
        <v-flex xs12 sm6 md3 class="mt-3" :class="{ 'pr-3': smAndUp }">
          <v-autocomplete
            v-if="isPermissionActive('LIST_OPERATORS')"
            color="secondary"
            :filter="autocompleteFilter"
            :label="$t('fields.operator')"
            item-value="id"
            item-text="name"
            v-model="operatorId"
            :items="operators.list"
            outlined
            clearable
            hide-details
          />
        </v-flex>
        <v-flex xs12 sm6 md3 class="mt-3" :class="{ 'pr-3': smAndUp }">
          <v-autocomplete
            color="secondary"
            :filter="autocompleteFilter"
            :label="$t('fields.device')"
            v-model="printerId"
            :items="peripheralItems"
            outlined
            clearable
            hide-details
          />
        </v-flex>
      </v-layout>
      <!-- DATES -->
      <v-layout wrap>
        <v-flex xs12 sm6 md2 class="mt-3" :class="{ 'pr-3': smAndUp }">
          <date-picker
            :label="$t('shifts.fields.opened_since')"
            :initialValue="openedAfter"
            :value="openedAfter"
            @change="v => openedAfter = v"
            :clearable="!!openedAfter"
            outlined
          />
        </v-flex>
        <v-flex xs12 sm6 md2 class="mt-3" :class="{ 'pr-3': smAndUp }">
          <date-picker
            :label="$t('shifts.fields.opened_until')"
            :initialValue="openedBefore"
            :value="openedBefore"
            @change="v => openedBefore = v"
            :clearable="!!openedBefore"
            outlined            
          />
        </v-flex>
        <v-flex xs12 sm6 md2 class="mt-3" :class="{ 'pr-3': smAndUp }">
          <date-picker
            :label="$t('shifts.fields.closed_since')"
            :initialValue="closedAfter"
            :value="closedAfter"
            @change="v => closedAfter = v"
            :clearable="!!closedAfter"
            outlined            
          />
        </v-flex>
        <v-flex xs12 sm6 md2 class="mt-3" :class="{ 'pr-3': smAndUp }">
          <date-picker
            :label="$t('shifts.fields.closed_until')"
            :initialValue="closedBefore"
            :value="closedBefore"
            @change="v => closedBefore = v"
            :clearable="!!closedBefore"
            outlined            
          />
        </v-flex>
      </v-layout>
      <item-list
        :listId="table"
        :list="shifts.list"
        :availableHeaders="headers"
        :appendActions="appendActions"
        :refreshColumnDecoration="refreshColumnDecoration"
        decorateColumnAction="shifts/decorateColumn"
        externalPagination
        serverside
        class="mt-2"
        @closeShift="shift => closeShift(shift.id)"
        @getTicket="shift => getShiftTicket(shift)"
        @retryGet="shift => getShiftTicket(shift)"
        @retryPrint="shift => printShiftTicket(shift)"
        @info="displayInfo"
      />
    </v-layout>
    <shift-side-form :triggerOpen="openInfo"/>
    <supervisor-modal :triggerOpen="openSupervisorModal" @finished="continueProcess()"/>
    <close-shift-modal :triggerOpen="closeShiftModal" :shiftId="closingShiftId" @closeShift="refreshData()" @isLoading="(v) => loadingCancel = v" inNavigationMenu />
    <confirm-modal
      :title="!unmatchingShifts ? $t('shifts.actions.close') : printerShift ? $t('shifts.actions.close_printer') : $t('shifts.actions.close_operator')"
      :triggerOpen="confirmCloseShift"
      @accept="continueProcess()"
    />
  </v-container>
</template>

<script>
import permissionMixins from '@/mixins/Permission'
import searchMixins from '@/mixins/Search'
import paginationMixins from '@/mixins/Pagination'
import vuetifyMixins from '@/mixins/Vuetify'
import printerMixins from '@/mixins/PrinterPeripheralActions'
import peripheralMixins from '@/mixins/PeripheralSocket'
import shiftMixins from '@/mixins/Shifts'
import { mapState, mapGetters } from 'vuex'
import { shiftStatusItems } from '@/config'
import dayjs from 'dayjs'
import { ShiftRequests } from '@/services/shift.requests'
const shiftService = new ShiftRequests()

export default {
  data () {
    return {
      table: 'Shifts',
      refreshColumnDecoration: null,
      dayjs,
      shiftStatusItems,
      headers: [
        { text: this.$t('fields.id'), path: 'id', show: false },
        { text: this.$t('shifts.fields.opened'), path: 'openedAt', show: true, isFullDate: true },
        { text: this.$t('shifts.fields.closed'), path: 'closedAt', show: true, isFullDate: true },
        { text: this.$t('fields.operator'), path: 'operator.name', show: true, permission: 'LIST_OPERATORS',
          willHaveValue: (item) => { return !!item.operatorId }
        },
        { text: this.$t('fields.station'), path: 'station.name', show: true, permission: 'LIST_STATIONS',
          willHaveValue: (item) => { return !!item.stationId }
        },
        { text: this.$t('fields.device'), path: 'device', paths: ['device.label', 'device.model', 'device.id'], show: true,
          willHaveValue: (item) => { return !!item.printerId }
        },
        { text: this.$t('shifts.fields.opened_by'), path: 'openedByOperator.name', show: true, permission: 'LIST_OPERATORS',
          willHaveValue: (item) => { return !!item.openedByOperatorId }
        },
        { text: this.$t('shifts.fields.closed_by'), path: 'closedByOperator.name', show: true, permission: 'LIST_OPERATORS',
          willHaveValue: (item) => { return !!item.closedByOperatorId }
        },
      ],
      appendActions: [
        { icon: 'mdi-clipboard-check', action: 'closeShift', text: this.$t('shifts.actions.close'),
          show: (item) => !item.closedByOperatorId && this.failedTicketGenerationShiftId !== item.id && this.failedPrintingShiftId !== item.id,
          loading: (item) => item.id === this.closingShiftId && this.loadingCancel
        },
        { icon: 'mdi-printer', action: 'getTicket', text: this.$t('shifts.actions.print'),
          show: (item) => !!item.closedByOperatorId && this.failedTicketGenerationShiftId !== item.id && this.failedPrintingShiftId !== item.id,
          loading: (item) => item.id === this.gettingShiftTicketShiftId
        },
        { icon: 'mdi-replay', action: 'retryGet', text: this.$t('actions.retry'),
          show: (item) => item.id === this.failedTicketGenerationShiftId
        },
        { icon: 'mdi-replay', action: 'retryPrint', text: this.$t('actions.retry'),
          show: (item) => this.failedPrintingShiftId === item.id
        },
        { icon: 'mdi-information-outline', action: 'info', text: this.$t('actions.info'), show: true }
      ],
      status: null,
      operatorId: null,
      printerId: null,
      openedAfter: null,
      openedBefore: null,
      closedAfter: null,
      closedBefore: null,
      base64Ticket: null,
      gettingShiftTicketShiftId: null,
      failedTicketGenerationShiftId: null,
      failedPrintingShiftId: null,
      openInfo: new Date(),
      loadingCancel: false
    }
  },
  computed: {
    ...mapState(['shifts', 'pagination', 'operators', 'peripherals']),
    ...mapGetters({
      parkFacilityId: 'configuration/parkFacilityId',
    }),
    peripheralItems () {
      return this.peripherals.completeList
        .filter(peripheral => {
          const facilityId = peripheral.station && peripheral.station.zone ? peripheral.station.zone.facilityId : null
          return peripheral.type === 'PRINTER' && facilityId && facilityId === this.parkFacilityId
        })
        .map((peripheral) => {
          return {
            text: peripheral.label || peripheral.model || peripheral.id,
            value: peripheral.id
          }
        })
    }
  },
  watch: {
    status (status) {
      if (status) {
        sessionStorage.setItem('filterConfig:Shifts:Status', status)
      } else {
        sessionStorage.removeItem('filterConfig:Shifts:Status')
      }
      this.debounceList()
    },
    operatorId (id) {
      if (id) {
        sessionStorage.setItem('filterConfig:Shifts:Operator', id)
      } else {
        sessionStorage.removeItem('filterConfig:Shifts:Operator')
      }
      this.debounceList()
    },
    printerId (id) {
      if (id) {
        sessionStorage.setItem('filterConfig:Shifts:PrinterId', id)
      } else {
        sessionStorage.removeItem('filterConfig:Shifts:PrinterId')
      }
      this.debounceList()
    },
    openedAfter (value) {
      if (value) {
        sessionStorage.setItem('filterConfig:Shifts:OpenedAfter', dayjs(value).toISOString())
      } else {
        sessionStorage.removeItem('filterConfig:Shifts:OpenedAfter')
      }
      this.debounceList()
    },
    openedBefore (value) {
      if (value) {
        sessionStorage.setItem('filterConfig:Shifts:OpenedBefore', dayjs(value).toISOString())
      } else {
        sessionStorage.removeItem('filterConfig:Shifts:OpenedBefore')
      }
      this.debounceList()
    },
    closedAfter (value) {
      if (value) {
        sessionStorage.setItem('filterConfig:Shifts:ClosedAfter', dayjs(value).toISOString())
      } else {
        sessionStorage.removeItem('filterConfig:Shifts:ClosedAfter')
      }
      this.debounceList()
    },
    closedBefore (value) {
      if (value) {
        sessionStorage.setItem('filterConfig:Shifts:ClosedBefore', dayjs(value).toISOString())
      } else {
        sessionStorage.removeItem('filterConfig:Shifts:ClosedBefore')
      }
      this.debounceList()
    },
    'shifts.operatorShift' () {
      this.list()
    }
  },
  methods: {
    initializeFilterValues () {
      this.status = sessionStorage.getItem('filterConfig:Shifts:Status') || null
      this.operatorId = sessionStorage.getItem('filterConfig:Shifts:Operator') || null
      this.printerId = sessionStorage.getItem('filterConfig:Shifts:PrinterId') || null
      this.openedAfter = sessionStorage.getItem('filterConfig:Shifts:OpenedAfter') || null
      this.openedBefore = sessionStorage.getItem('filterConfig:Shifts:OpenedBefore') || null
      this.closedAfter = sessionStorage.getItem('filterConfig:Shifts:ClosedAfter') || null
      this.closedBefore = sessionStorage.getItem('filterConfig:Shifts:ClosedBefore') || null
    },
    list () {
      this.$store.commit('pagination/setLoading', { table: this.table, loading: true })
      this.$store.dispatch('shifts/list', {
        limit: this.tablePagination.pages.itemsPerPage,
        offset: (this.tablePagination.pages.page - 1) * this.tablePagination.pages.itemsPerPage,
        status: this.status,
        operatorId: this.operatorId,
        printerId: this.printerId,
        openedAfter: this.openedAfter,
        openedBefore: this.openedBefore ? dayjs(this.openedBefore).add(dayjs().utcOffset(), 'm').set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).toISOString()  : null,
        closedAfter: this.closedAfter,
        closedBefore: this.closedBefore ? dayjs(this.closedBefore).add(dayjs().utcOffset(), 'm').set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).toISOString()  : null
      }).then((response) => {
        this.closingShiftId = null
        this.updatePagination(response)
        this.refreshColumnDecoration = new Date()
      })
    },
    printShiftTicket ({ id }) {
      this.failedPrintingShiftId = null
      this.sendPrinterAction(this.base64Ticket, 'application/pdf', () => {}, () => {
        this.failedPrintingShiftId = id
      })
    },
    getShiftTicket (shift) {
      this.gettingShiftTicketShiftId = shift.id
      this.failedTicketGenerationShiftId = null
      shiftService.getShiftTicket(shift.id).then((base64Pdf) => {
        this.base64Ticket = base64Pdf
        this.printShiftTicket(shift)
      }).catch((error) => {
        this.failedTicketGenerationShiftId = shift.id
      }).finally(() => {
        this.gettingShiftTicketShiftId = null
      })
    },
    refreshData () {
      this.$store.dispatch('shifts/reloadShifts')
      this.list()
    },
    displayInfo (shift) {
      this.$store.commit('shifts/setRead', shift)
      this.$store.dispatch('shifts/decorateRead')
      this.openInfo = new Date()
    }
  },
  created () {
    this.initializeFilterValues()
    this.list()
    if (this.isPermissionActive('LIST_OPERATORS')) {
      this.$store.dispatch('operators/list', { save: true }).catch(() => {})
    }
    this.$store.dispatch('peripherals/listAll', { decorate: true, save: true }).catch(() => {})
    this.$store.dispatch('paymentMethods/list').catch(() => {})
  },
  components: {
    ItemList: () => import('@/components/app-components/listing/ItemList.vue'),
    ShiftSideForm: () => import('@/views/forms/ShiftSideForm.vue'),
    ConfirmModal: () => import('@/components/app-components/modals/ConfirmModal.vue'),
    CloseShiftModal: () => import('@/components/view-components/modals/CloseShiftModal.vue'),
    SupervisorModal: () => import('@/components/view-components/modals/SupervisorModal.vue'),
    DatePicker: () => import('@/components/app-components/DatePicker.vue')
  },
  mixins: [
    searchMixins,
    paginationMixins,
    vuetifyMixins,
    permissionMixins,
    printerMixins,
    peripheralMixins,
    shiftMixins
  ]
}
</script>
