<template>
  <full-screen-logo-wrapper>
    <template v-slot:content>
      <v-form ref="form">
        <v-text-field
          :label="$t('fields.username_or_mail')"
          :readonly="state.loading"
          :rules="[rules.required]"
          :error="loginError"
          v-model="credentials.user"
          color="secondary"
          class="mb-4"
          hide-details
          outlined />
        <v-text-field
          :label="$t('fields.password')"
          :readonly="state.loading"
          v-on:keyup.enter.prevent="signIn()"
          :rules="[rules.required]"
          :error="loginError"
          v-model="credentials.password"
          color="secondary"
          @click:append="show = !show"
          :type="show ? 'text' : 'password'"
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          outlined
          hide-details
          class="mb-2" />
      </v-form>
      <v-btn id="login-btn" :loading="state.loading" outlined block color="primary" @click="signIn()" class="mt-3">{{$t('actions.sign_in')}}</v-btn>
    </template>
  </full-screen-logo-wrapper>
</template>

<script>
import { mapState } from 'vuex'
import routeNames from '@/router/routes'
export default {
  data () {
    return {
      routeNames,
      show: false,
      credentials: {
        user: null,
        password: null
      },
      loginError: null,
      rules: {
        required: value => !!value || ''
      }
    }
  },
  computed: {
    ...mapState(['state'])
  },
  components: {
    Logo: () => import('@/components/app-components/Logo.vue'),
    FullScreenLogoWrapper: () => import('@/components/view-components/FullScreenLogoWrapper.vue')
  },
  methods: {
    signIn () {
      if (this.$refs.form.validate()) {
        this.loginError = false
        this.$store.dispatch('auth/signIn', { user: this.credentials.user, password: this.credentials.password }).catch((error) => {
          this.loginError = true
        })
      }
    }
  }
}
</script>
